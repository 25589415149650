import hero1 from "../../assets/images/frames/Hero_001.jpg";
import hero2 from "../../assets/images/frames/Hero_002.jpg";
import hero3 from "../../assets/images/frames/Hero_003.jpg";
import hero4 from "../../assets/images/frames/Hero_004.jpg";
import hero5 from "../../assets/images/frames/Hero_005.jpg";
import hero6 from "../../assets/images/frames/Hero_006.jpg";
import hero7 from "../../assets/images/frames/Hero_007.jpg";
import hero8 from "../../assets/images/frames/Hero_008.jpg";
import hero9 from "../../assets/images/frames/Hero_009.jpg";

import HomeTransitionImage from "../../components/HomeTransitionImage/HomeTransitionImage";
import './Home.scss';

function Home() {
    const heros = [hero1, hero2, hero3, hero4, hero5, hero6, hero7, hero8, hero9];
    return (
        <div className="home">
            <div className="home__content">
                <HomeTransitionImage images={heros} interval={4000} />
            </div>
        </div>
    );
}

export default Home;