import { Link } from 'react-router-dom';
import './PortfolioCategoryCard.scss';
import { useEffect, useRef } from 'react';

/**
 * 
 * @param {object} props 
 * @param {string} props.name - The name of the category
 * @param {string} props.hoverImage
 * @param {string} props.image
 */
function PortfolioCategoryCard({ name, hoverImage, image }) {
  // const imageRef = useRef(null);

  // useEffect(() => {
  //   if (transitionDelay) {
  //     const interval = setInterval(() => {
  //       imageRef.current.classList.add('portfolio-category-card__image--hide');
  //       setTimeout(() => imageRef.current.classList.remove('portfolio-category-card__image--hide'), transitionDuration);

  //     }, transitionDelay);
  //     return () => clearInterval(interval);
  //   }
  // }, [transitionDelay, transitionDuration]);


  return <div className="portfolio-category-card">
    <Link to={`/portfolio/${name}`}>
      <img src={hoverImage} className="portfolio-category-card__image-hover" alt={`${name} hover`} />
      <img src={image} className="portfolio-category-card__image" alt={`${name}`} />
    </Link>
  </div>

}
export default PortfolioCategoryCard;