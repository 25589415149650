import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "../src/components/Nav/Nav";
import Home from "./pages/Home/Home";
import About from "../src/pages/About/About";
import Portfolio from "../src/pages/Portfolio/Portfolio";
import Gallery from "../src/pages/Gallery/Gallery";
import Press from "../src/pages/Press/Press";
import Publications from "../src/pages/Publications/Publications";
import Contact from "../src/pages/Contact/Contact";
import Footer from "../src/components/Footer/Footer";
import "../src/styles/partials/_global.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:category" element={<Gallery />} />
          <Route path="/press" element={<Press />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
