import { useParams } from "react-router-dom";
import "./Gallery.scss";
import React from "react";
import portfolioData from "../../assets/data/portfolio.json";
import ReactResponsiveGallery from "react-responsive-gallery";
// import ResponsiveGallery from "react-responsive-gallery/dist/declarations";

function Gallery() {
    const { category } = useParams();
    const images = portfolioData.categories[category];
    console.log(images);

    return (
        <div className="gallery__background">
            <div className="gallery__container">
                {/* {images.map((e, i) => < img key={i} src={`/portfolio${e}`} />)} */}
                <ReactResponsiveGallery useLightBox images={images.map((e, i) => ({
                    src: `/portfolio${e}`, orderS: i + 1, orderM: i + 1, orderL: i + 1
                }))} />
            </div>
        </div>
    );
}

export default Gallery;