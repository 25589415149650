import "./HomeTransitionImage.scss";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useEffect, useState } from "react";
/**
 *
 * @param {object} props
 * @param {string[]} props.images
 * @param {number} props.interval
 */
function HomeTransitionImage({ images, interval = 1000 }) {
  const [state, setState] = useState({
    index: 0,
    transitioning: false
  });


  // Set the interval to switch images automatically
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setState((state) => ({ ...state, index: (state.index + 1) % images.length }));
    }, interval);
    return () => clearInterval(slideInterval);
  }, [interval, images]);

  return <div className="home-transition-image">
    <div className="home-transition-image__frame">
      <TransitionGroup>
        <CSSTransition
          key={state.index}
          timeout={500}
          classNames="fade"
        >
          <img
            className="home-transition-image__image"
            src={images[state.index]}
            alt={`Hero ${state.index + 1}`}
          />
        </CSSTransition>
      </TransitionGroup>

    </div>
  </div>;
}
export default HomeTransitionImage;
