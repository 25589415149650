import logo from "../../assets/images/signature/logo_001.png";
import './Nav.scss';
import { NavLink } from "react-router-dom";

function Nav() {
    return (
        <div>
            <nav className="nav-bar">
                <div className="nav-logo__container">
                    <NavLink to="/"><img src={logo} alt="logo" className="nav-logo" /></NavLink>
                </div>
                <div className="nav-bar__background">
                    <div className="nav-bar__container">
                        <NavLink to="/portfolio" className="nav-bar__link"><div className="nav-bar__item">PORTFOLIO</div></NavLink>
                        <NavLink to="/press" className="nav-bar__link"><div className="nav-bar__item">PRESS</div></NavLink>
                        <NavLink to="/publications" className="nav-bar__link"><div className="nav-bar__item">PUBLICATIONS</div></NavLink>
                        <NavLink to="/about" className="nav-bar__link"><div className="nav-bar__item">ABOUT</div></NavLink>
                        <NavLink to="/contact" className="nav-bar__link"><div className="nav-bar__item">CONTACT</div></NavLink>
                    </div>
                </div>
            </nav>

        </div>
    );
}

export default Nav;