import "./Portfolio.scss";
import Abstract from "../../assets/images/frames/Hero_009.jpg";
import Aquatica from "../../assets/images/frames/Hero_007.jpg";
import Architecture from "../../assets/images/frames/Hero_003.jpg";
import Boating from "../../assets/images/frames/Hero_002.jpg";
import Cars from "../../assets/images/frames/Hero_008.jpg";
import Commercial from "../../assets/images/frames/Hero_012.jpg";
import Fauna from "../../assets/images/frames/Hero_001.jpg";
import Flora from "../../assets/images/frames/Hero_005.jpg";
import Landscapes from "../../assets/images/frames/Hero_014.jpg";
import Portraits from "../../assets/images/frames/Hero_013.jpg";
import Sports from "../../assets/images/frames/Hero_004.jpg";
import Weddings from "../../assets/images/frames/Hero_015.jpg";
import AbstractH from "../../assets/images/framesHover/Hero_009.jpg";
import AquaticaH from "../../assets/images/framesHover/Hero_007.jpg";
import ArchitectureH from "../../assets/images/framesHover/Hero_003.jpg";
import BoatingH from "../../assets/images/framesHover/Hero_002.jpg";
import CarsH from "../../assets/images/framesHover/Hero_008.jpg";
import CommercialH from "../../assets/images/framesHover/Hero_012.jpg";
import FaunaH from "../../assets/images/framesHover/Hero_001.jpg";
import FloraH from "../../assets/images/framesHover/Hero_005.jpg";
import LandscapesH from "../../assets/images/framesHover/Hero_014.jpg";
import PortraitsH from "../../assets/images/framesHover/Hero_013.jpg";
import SportsH from "../../assets/images/framesHover/Hero_004.jpg";
import WeddingsH from "../../assets/images/framesHover/Hero_015.jpg";
import PortfolioCategoryCard from '../../components/PortfolioCategoryCard/PortfolioCategoryCard';

function Portfolio() {
    return (
        <div className="portfolio__background">
            <div className="portfolio__container">
                <PortfolioCategoryCard name="abstract" hoverImage={AbstractH} image={Abstract} />
                <PortfolioCategoryCard name="aquatica" hoverImage={AquaticaH} image={Aquatica} />
                <PortfolioCategoryCard name="architecture" hoverImage={ArchitectureH} image={Architecture} />
                <PortfolioCategoryCard name="boating" hoverImage={BoatingH} image={Boating} />
                <PortfolioCategoryCard name="cars" hoverImage={CarsH} image={Cars} />
                <PortfolioCategoryCard name="commercial" hoverImage={CommercialH} image={Commercial} />
                <PortfolioCategoryCard name="fauna" hoverImage={FaunaH} image={Fauna} />
                <PortfolioCategoryCard name="flora" hoverImage={FloraH} image={Flora} />
                <PortfolioCategoryCard name="landscapes" hoverImage={LandscapesH} image={Landscapes} />
                <PortfolioCategoryCard name="portraits" hoverImage={PortraitsH} image={Portraits} />
                <PortfolioCategoryCard name="sports" hoverImage={SportsH} image={Sports} />
                <PortfolioCategoryCard name="weddings" hoverImage={WeddingsH} image={Weddings} />
            </div>
        </div>
    );
}



export default Portfolio;