import Portrait from "../../assets/images/about/portrait_001.jpg";
import './About.scss';

function About() {
    return (
        <div className="about__background">
            <div className="about__container">
                <div className="about__left">
                    <img src={Portrait} className="about__image" alt="artist portrait" />
                </div>
                <div className="about__right">
                    <article className="about__text">An established professional photographer, Rodney Paavola has over 25 years of experience.
                        It all started in the late 1990’s during a winter trip to Lapland.  He borrowed his families point and shoot 35mm film camera
                        to document and capture images throughout the journey.  Once returning back home to sunny South Florida, the film was quickly
                        sent for development.  Upon receiving the photographs, one image stood out..  “Winter Dream”.  It is a panoramic landscape of
                        snow covered trees taken on a ski slope fell. This photograph portrayed an untrained natural eye for composition.  What many
                        individuals may pass by, Paavola stops to notice and captures it in a unique perspective to display all its glory.
                        The hobby and passion ignited.  Everyday ordinary things were photographed and the self taught skill expanded.  Nature and
                        wildlife are the calling.  The hobby then transitioned into a career.  Paavola has a BBA in Real Estate, so a natural direction
                        to go was architectural photography.  For clients, he has photographed throughout the Palm Beach’s, homes and estates for sale.
                        During that time also actively photographing portraits, weddings and commercial products.  In 2009 he submitted an image into an
                        international National Geographic photo contest which he won second place with the abstract image “Great Plains”. It is a close-up
                        of a giraffes neck but taken in an abnormal perspective being parallel. At first glance the image portrays a safari landscape but
                        upon closer inspection the realisation comes that it is an animal. Throughout Paavola’s career, he has had numerous publications,
                        press articles and exhibitions.  His goal has always been portraying to viewers the awe inspiring beauty of nature.</article>
                </div>
            </div>
        </div>
    );
}

export default About;