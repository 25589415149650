import './SlideShow.scss';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


function SlideShow({ images }) {
  return (
    <div className="slide-show">
      <Slide
        duration={3600000} //Huge transition interval so it doesn't auto-advance
      >
        {images.map((image, index) => (
          <div className="slide-show__slide" key={index}>
            <div className="slide-show__slide-content" style={{ backgroundImage: `url(${image})` }} />
          </div>
        ))}
      </Slide>
    </div>
  );
}
export default SlideShow;