import './Publications.scss';
import PageFlip from '../../components/PageFlip/PageFlip';
import pub_001 from '../../assets/images/publications/pub_001.jpg';
import pub_002 from '../../assets/images/publications/pub_002.jpg';
import pub_003 from '../../assets/images/publications/pub_003.jpg';
import pub_004 from '../../assets/images/publications/pub_004.jpg';
import pub_005 from '../../assets/images/publications/pub_005.jpg';
import React from 'react';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";


function Publications() {
    const [lightboxState, setLightboxState] = React.useState({
        open: false,
        index: null,
    });

    const images = [pub_001, pub_002, pub_003, pub_004, pub_005];

    const handleZoom = (event, index) => {
        setLightboxState({
            open: true,
            index: index,
        });
    }

    return (
        <section className="publications">

            <div className="publications__content">
                <div className="publications__container">
                    <div className="publications__page-flip-container">
                        <PageFlip images={images} onCl onZoom={handleZoom} />
                    </div>

                    <Lightbox
                        open={lightboxState.open}
                        close={() => setLightboxState(false)}
                        slides={images.map((image, index) => ({ src: image }))}
                        index={lightboxState.index}
                    />

                </div>
            </div>

        </section>

    );
}

export default Publications;