import './Footer.scss';

function Footer() {
    return (
        <div>
            <footer className="footer__container">
                <div className="footer-item">© 2023 Rodney Paavola. All rights reserved.</div>
            </footer>
        </div>
    );
}

export default Footer;