import "./Contact.scss";
import Postcard from "../../assets/images/contact/postcard_001.jpg";

function Contact() {
    return (
        <div className="contact__background">
            <div className="contact__container">
                <img src={Postcard} className="contact__image" alt="postcard" />
                <form className="contact__form">
                    <label className="contact__form-label">Name:<input type="text" className="contact__form-input"></input></label>
                    <label className="contact__form-label">Email:<input type="email" className="contact__form-input"></input></label>
                    <label className="contact__form-label">Subject:<input type="text" className="contact__form-input"></input></label>
                    <label className="contact__form-label">Message:<textarea type="text" className="contact__form-input contact__form-input-message"></textarea></label>
                    <button className="contact__form-button">SEND</button>
                </form>
                <a href="mailto: rpaavola@mac.com" className="contact__email-link"><div className="contact__email">rpaavola@mac.com</div></a>
            </div>
        </div>
    );
}

export default Contact;