import React, { useRef } from 'react';
import './PageFlip.scss';
import HTMLFlipBook from "react-pageflip";


/**
 * @param {object} props 
 * @param {string[]} props.images
 * @param {(event: Event,index: number) => void} props.onZoom
 * @returns 
 */
function PageFlip({ images, onZoom }) {
  const book = useRef();


  const Page = React.forwardRef((props, ref) => {
    return (
      <div className="page-flip__page" ref={ref}>
        <div className="page-flip__page-header">
          <button className="page-flip__button" onClick={props.onClick}>Full</button>
        </div>
        <div className="page-flip__page-content" >
          {props.children}
        </div>
      </div>
    );
  });

  const onFlipClick = (event, direction) => {
    if (direction === -1) {
      book.current.pageFlip().flipPrev();

    } else {
      book.current.pageFlip().flipNext();

    }
  };

  return (
    <div className="page-flip">
      <div className="page-flip__page-flip-container">
        <HTMLFlipBook

          width={550}
          height={733}
          size="stretch"
          minWidth={288}
          maxWidth={1000}
          minHeight={400}
          maxHeight={1533}
          ref={book}
          renderOnlyPageLengthChange={true}
          maxShadowOpacity={0.5}
          showCover={true}
          mobileScrollSupport={true}
        >
          {images.map((image, index) => (
            <Page key={index} className="page-flip__page" number={index} onClick={(e) => onZoom(e, index)}  >
              <img className="page-flip__page-image" src={image} alt="page" />
            </Page>
          ))}
        </HTMLFlipBook>



      </div>

      <div className="page-flip__button-row">
        <button className="page-flip__button" onClick={(e) => onFlipClick(e, -1)}>
          Previous page
        </button>
        <div>

        </div>
        <button className="page-flip__button" onClick={(e) => onFlipClick(e, 1)}>
          Next page
        </button>
      </div>



    </div>
  );
}
export default PageFlip;