import Newspaper from "../../assets/images/press/bg_newspaper_002.jpg";
import './Press.scss';
import img1 from '../../assets/images/press/press_001.jpg';
import img2 from '../../assets/images/press/press_002.jpg';
import img3 from '../../assets/images/press/press_003.jpg';
import img4 from '../../assets/images/press/press_004.jpg';
import SlideShow from "../../components/SlideShow/SlideShow";


function Press() {
    return (
        <div className="press">
            <div className="press__content">
                <div className="press__content press__content-background-overlay"></div>
                <img className="press__content-background" src={Newspaper} alt="newspaper background" />
                <div className="press__slide-show-wrapper">
                    <div className="press__slide-show-container">
                        <SlideShow images={[img1, img2, img3, img4]} />
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Press;